import {BehaviorSubject, combineLatest, of, switchMap, tap} from 'rxjs';
import {getUser} from '@esgi/core/authentication';
import {ElementStatus} from '@esgi/ui/form';
import {BaseService} from '@esgi/core/service';
import {
	createProfileTabForm,
	rangeLengthCustomValidator,
	studentUsernameAvailableCustomValidator,
	studentIDNValidator,
} from './forms/profile';
import {createInformationForm} from './forms/information';
import {createLocationForm} from './forms/location';
import {
	ClassItem,
	Credential,
	Dictionary,
	DictionaryItem,
	GroupItem,
	ImageData,
	Information,
	Location,
	NewPhoto,
	Profile,
	StudentAddOrEditModel,
	StudentModel,
	StudentProfileMode,
	StudentSpecialProgram,
	Student,
} from './types';
import {classesStore, groupsStore, studentsStore} from '@esgi/main/libs/store';
import {dictionary} from './constants';
import {extractBase64String, stringArrayToNumberArray, toISOStringWithoutTimeZone} from './utils';
import {isEqual} from 'underscore';
import {V2StudentsController} from '@esgi/contracts/esgi';
import {
	Response,
} from '@esgi/contracts/esgi/types/esgi.students/endpoints/esgi.apigateway/modules/students/profile/update/init/response';
import {
	UpdateRequest,
} from '@esgi/contracts/esgi/types/esgi.apigateway/api/controllers/v2/modules/forms/students/update-request';
import {dispatchAppEvent} from '@esgillc/events';
import {AddStudent, EditStudent, RemoveStudent} from '../../events';

export class StudentProfileService extends BaseService {
	public studentID = new BehaviorSubject<string>(null);
	public initData = new BehaviorSubject<Response>(null);
	public initDictionaryData = new BehaviorSubject<Dictionary>(null);
	public newPhoto = new BehaviorSubject<NewPhoto>(null);
	public limitStudents= new BehaviorSubject(Infinity);
	public classes = new BehaviorSubject<ClassItem[]>(null);
	public groups = new BehaviorSubject<GroupItem[]>(null);
	public students= new BehaviorSubject<Student[]>(null);
	public mode = new BehaviorSubject<StudentProfileMode>(null);
	public canExportID = new BehaviorSubject<boolean>(true);
	public duplicateError = new BehaviorSubject<string | null>(null);
	public usernamePlaceholder = new BehaviorSubject('');

	public profile = createProfileTabForm();
	public location = createLocationForm();
	public information = createInformationForm();

	private initialState: Response['profile'] & Response['location'] & Response['information'] = null;
	private preSelectedClassIds: null | string = null;
	private currentUser = getUser();
	private controller = new V2StudentsController();

	constructor() {
		super();

		this.location.controls.classIDs.onChanged.subscribe(((classes) => {
			this.updateGroups(classes.currState.value);
		}));

		this.completeOnDestroy(combineLatest([
			this.profile.onChanged,
			this.location.onChanged,
			this.information.onChanged,
		])).subscribe();
	}

	public init = (mode: StudentProfileMode, studentID?: string | null, classId?: string | null) => {

		this.studentID.next(studentID);
		this.mode.next(mode);

		if (this.currentUser?.canManageClasses) {
			this.preSelectedClassIds = classId;
		}

		this.profile.controls.username.validators.push(rangeLengthCustomValidator(4, 20, this.profile.controls.password));
		this.profile.controls.password.validators.push(rangeLengthCustomValidator(4, 20, this.profile.controls.username));

		return this.controller.init().pipe(tap((response) => {
			const languagesObjectLink = response.value.dictionary.languages;
			languagesObjectLink.sort((a, b) => a.name.toLocaleUpperCase().localeCompare(b.name.toLocaleUpperCase()));

			const indexOfOtherOption = languagesObjectLink.findIndex(({name}) => name.toLocaleLowerCase() === 'other');

			if (indexOfOtherOption !== -1) {
				const otherOption = languagesObjectLink.splice(indexOfOtherOption, 1)[0];
				languagesObjectLink.splice(languagesObjectLink.length, 1, otherOption);
			}

			this.initDictionaryData.next({
				...dictionary,
				...response.value.dictionary,
			});
			this.limitStudents.next(response.value.limit?.max);
		}),
		switchMap(() => {
			if (studentID) {
				return this.controller.updateInit({studentID: Number(this.studentID.value)}).pipe(tap((response) => {
					this.initData.next(response.value);
					this.newPhoto.next(response.value?.profile.photo?.imageUrl ? response.value?.profile.photo : null);
				}));
			} else {
				return of(null);
			}
		}))
			.pipe(tap({
				next: () => {
					/** Get student classes, students and groups data. */
					this.getClassesGroupsStudents();
					this.profileInit();
				},
				complete: () => {
					this.initializeStudentProfileFormStatuses();
				},
			}));
	};

	public profileInit = () => {
		/** Fill forms. */
		if (this.mode.value !== StudentProfileMode.add) {
			const {profile, location, information} = this.initData.value;

			this.profile.value = {
				studentIDN: profile?.studentIDN?.toString(),
				lastName: profile?.lastName,
				firstName: profile?.firstName,
				birthDate: profile?.birthDate ? [new Date(profile.birthDate)] : [],
				exportIDN: profile?.exportIDN,
				gradeLevel: [profile?.gradeLevelID.toString()],
				language: [profile?.languageID.toString()],
				gender: [profile?.gender],
				username: profile?.credential?.userName,
				password: profile?.credential?.password,
			};

			this.location.value = {
				classIDs: location?.classIDs.map(cl => cl.toString()) ?? [],
				groupIDs: location?.groupIDs.map(g => g.toString()) ?? [],
				specialistGroupIDs: location?.specialistGroupIDs.map(s => s.toString()) ?? [],
			};

			const parseBackendNameValue = (dictionaryItems: DictionaryItem<any>[], backendValue: string | number) => {
				if (!backendValue) {
					return null;
				}
				return dictionaryItems?.find(item => item.valueName === String(backendValue))?.id?.toString();
			};
			const parsedRace = parseBackendNameValue(dictionary.races, information?.race);
			const parsedLunchStatus = parseBackendNameValue(dictionary.lunchStatuses, information?.lunchStatus);
			const parsedSpecialPrograms = dictionary.specialPrograms.filter(item => {
				return information.studentSpecialPrograms.includes(item.valueName as unknown as StudentSpecialProgram);
			}).map(item => item.id);
			this.information.value = {
				race: parsedRace ? [parsedRace] : [],
				lunchStatus: parsedLunchStatus ? [parsedLunchStatus] : [],
				studentSpecialPrograms: parsedSpecialPrograms || [],
				comments: information?.comments || '',
			};
		}else if(this.mode.value === StudentProfileMode.add && this.preSelectedClassIds){
			if(this.preSelectedClassIds === '-1' && this.preSelectedClassIds.length > 1 && this.classes.value){
				this.location.controls.classIDs.value = [];
			}else{
				this.location.controls.classIDs.value = [this.preSelectedClassIds];
			}
		}


		const {profile, location, information} = this.createDataState();

		this.initialState = {
			...profile,
			...location,
			...information,
		};
		this.handleStudentUsernameValidation();
		this.handleStudentIDNValidation();
	};

	public save = (saveAnyway?: boolean) => {
		const {profile, location, information} = this.createDataState();

		const hadImage = this.initData.value?.profile?.photo?.imageUrl;

		const defaultPhoto = this.mode.value === StudentProfileMode.add ? null : {
			crop: this.initData.value.profile?.photo?.crop || null,
			imageBase64: null,
			imageCropBase64: null,
			remove: false,
			contentType: 'string',
		};

		const newPhoto = this.newPhoto.value ? {
			crop: this.newPhoto.value.crop,
			imageBase64: this.newPhoto.value.imageUrl === this.initData.value?.profile.photo?.imageUrl ? null : extractBase64String(this.newPhoto.value.imageUrl),
			imageCropBase64: this.newPhoto.value.imageCropUrl === this.initData.value?.profile.photo?.imageCropUrl ? null : extractBase64String(this.newPhoto.value.imageCropUrl),
			remove: false,
		} : defaultPhoto;

		const model = {
			profile: {...profile, birthDate: profile.birthDate || null},
			location,
			information,
			studentID: this.studentID.value ? Number(this.studentID.value) : null,
			createAnyway: false,
			newPhoto: hadImage && !this.newPhoto.value ? {
				imageBase64: null,
				imageCropBase64: null,
				crop: {x: 0, y: 0, zoom: 1},
				remove: true,
			} : newPhoto,
		};

		const isSaveAnyway = saveAnyway
			|| (this.initData.value?.profile?.firstName === model.profile.firstName
				&& this.initData.value?.profile?.lastName === model.profile.lastName);

		if (isSaveAnyway) {
			model.createAnyway = true;
		}

		return this.controller[this.studentID.value ? 'update' : 'create'](model as UpdateRequest).pipe(
			tap((response) => {
				if (this.mode.value === StudentProfileMode.add && response.isSuccess){
					this.studentID.next(response['value'].studentID.toString());
				}

				if (response.errors?.length && response.errors[0].type === 'duplicate') {
					this.duplicateError.next(response.errors[0].description);
				}

				if (response.isSuccess && !response.errors?.length) {
					this.duplicateError.next(null);

					const newEventModel = new StudentAddOrEditModel(
						this.location.value.classIDs.map(id => Number(id)),
						this.location.value.groupIDs.map(id => Number(id)),
						new StudentModel(
								 Number(this.studentID.value),
							 this.profile.controls.firstName.value,
							 this.profile.controls.lastName.value,
							 this.newPhoto.value?.imageUrl,
							 Number(this.initDictionaryData.value.gradeLevels.find(g => g.id === this.profile.controls.gradeLevel.value[0]).id),
							 false,
							 this.profile.controls.studentIDN.value,
							 Number(this.profile.controls.language.value),
						)
					);

					if(this.mode.value === StudentProfileMode.add){
						dispatchAppEvent(AddStudent, new AddStudent(newEventModel));
					}

					if(this.mode.value === StudentProfileMode.edit){
						dispatchAppEvent(EditStudent, new EditStudent(newEventModel));
					}
				}
			})
		);
	};

	public getClassesGroupsStudents = () => {
		this.completeOnDestroy(groupsStore().get()).subscribe(g => this.groups.next(g.data));
		this.completeOnDestroy(classesStore().get()).subscribe(c => this.classes.next(c.data));
		this.completeOnDestroy(studentsStore().get()).subscribe(c => this.students.next(c.data));
	};

	public deleteStudent = () => {
		 dispatchAppEvent(RemoveStudent, new RemoveStudent(Number(this.studentID.value)));
		 return this.controller.makeDelete({studentID: Number(this.studentID.value)});
	};

	public saveImage = (imageFileData: ImageData) => {
		const {cropParameters, croppedImage, image} = imageFileData;

		this.newPhoto.next({
			imageUrl: image,
			imageCropUrl: croppedImage,
			crop: cropParameters,
			remove: false,
		});
	};

	public deletePhoto = () => {
		this.newPhoto.next(null);
	};

	public compareStatesForEquality = () => {
		const {location, information, checkedProfileData} = this.createDataState();

		const currentState = {
			...checkedProfileData,
			...location,
			...information,
		};

		return isEqual(this.initialState, currentState);
	};

	private getDisabledFreeGroups = () => {
		if (this.groups.value) {
			const disabledGroupsDictionary = this.groups.value.reduce((acc: Record<string, boolean>, group) => {
				if (group.disabled) {
					acc[group.id] = true;
				}
				return acc;
			}, {});
			return this.location.value.groupIDs?.filter(group => !disabledGroupsDictionary[group]);
		}
		return this.location.value.groupIDs;
	};

	private updateGroups = (selectedClassIDs: string[]) => {
		this.groups.next(this.groups.value?.map(item => ({
			...item,
			disabled: !selectedClassIDs.includes(item.classID.toString()),
		})));
	};

	private createDataState = () => {
		const {
			firstName,
			lastName,
			birthDate,
			exportIDN,
			studentIDN,
			language,
			gender,
			gradeLevel,
			username,
			password,
		} = this.profile.value;

		const profile = new Profile(
			firstName,
			lastName,
			gender[0],
			toISOStringWithoutTimeZone(birthDate[0]) || '',
			studentIDN || null,
			exportIDN || null,
			Number(gradeLevel[0]),
			Number(language[0]),
			new Credential(username, password),
			this.newPhoto.value?.imageUrl ? this.newPhoto.value : {
				imageUrl: '',
				imageCropUrl: '',
				crop: {x: 0, y: 0, zoom: 0},
			},
		);

		const checkedProfileData = {
			...profile,
			credential: new Credential(username, password),
		};

		const {classIDs, specialistGroupIDs} = this.location.value;

		const location = new Location(
			this.initData.value?.location?.districtID,
			this.initData.value?.location?.schoolID,
			this.currentUser.globalSchoolYearID,
			this.initData.value?.location?.teacherID || this.currentUser.userID,
			null,
			stringArrayToNumberArray(classIDs),
			stringArrayToNumberArray(this.getDisabledFreeGroups()),
			stringArrayToNumberArray(specialistGroupIDs),
		);

		const {race, lunchStatus, studentSpecialPrograms, comments} = this.information.value;
		const information = new Information(
			race ? race[0] : null,
			lunchStatus[0],
			studentSpecialPrograms.length ? studentSpecialPrograms : [],
			comments || '',
		);

		return {
			profile,
			location,
			information,
			checkedProfileData,
		};
	};

	private handleStudentUsernameValidation = () => {
		this.profile.controls.username.validators.push(
			studentUsernameAvailableCustomValidator(
				this.controller,
				this.initialState?.credential,
				this.profile,
				Number(this.studentID.value),
				this.currentUser?.districtID,
				this.currentUser?.globalSchoolYearID),
		);
	};

	private handleStudentIDNValidation = () => {
		this.profile.controls.studentIDN.validators.push(
			studentIDNValidator(
				this.controller,
				Number(this.studentID.value),
				this.currentUser?.globalSchoolYearID),
		);
	};

	private initializeStudentProfileFormStatuses = () => {
		if (this.mode.value === StudentProfileMode.view) {
			this.profile.status = ElementStatus.disabled;
			this.location.status = ElementStatus.disabled;
			this.information.status = ElementStatus.disabled;
			return;
		}

		this.profile.status = ElementStatus.untouched;
		this.location.status = ElementStatus.untouched;
		this.information.status = ElementStatus.untouched;

		if (!this.currentUser.canManageClasses) {
			// NOTE: disabled status not working for fileds below, need to investigate.
			// now permission passed in the view directly
			this.location.controls.classIDs.status = ElementStatus.disabled;
			this.location.controls.groupIDs.status = ElementStatus.disabled;
		}

		if (this.mode.value === StudentProfileMode.edit) {
			if (!this.currentUser?.canEditStudents) {
				this.profile.controls.firstName.status = ElementStatus.disabled;
				this.profile.controls.lastName.status = ElementStatus.disabled;
				this.profile.controls.gender.status = ElementStatus.disabled;
				this.profile.controls.gradeLevel.status = ElementStatus.disabled;
				this.profile.controls.studentIDN.status = ElementStatus.disabled;
				this.profile.controls.exportIDN.status = ElementStatus.disabled;
				this.profile.controls.username.status = ElementStatus.disabled;
				this.profile.controls.password.status = ElementStatus.disabled;
			}
	
			if (!this.currentUser?.canEditStudentLanguage) {
				this.profile.controls.language.status = ElementStatus.disabled;
			}
	
			if (!this.currentUser?.canEditStudentMetaFields) {
				this.profile.controls.birthDate.status = ElementStatus.disabled;
				this.information.controls.race.status = ElementStatus.disabled;
				this.information.controls.lunchStatus.status = ElementStatus.disabled;
				this.information.controls.studentSpecialPrograms.status = ElementStatus.disabled;
				this.information.controls.comments.status = ElementStatus.disabled;
			}
		}
	};
}

