import {MutableRefObject, RefObject, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import {useBehaviorSubject, useStreamEffect} from '@esgi/ui/utils';
import {UserCard} from '@esgi/ui';
import {FlexBox} from '@esgi/ui/layout';
import {useService} from 'libs/core/src/service';
import {StudentProfileService} from './service';
import {ImageData, StudentProfileMode, StudentProfileTab} from './types';
import {StudentDetails} from './components/student-details';
import {TabsController} from './components/tabs-controller';
import {Profile} from './components/tabs/profile';
import {Location} from './components/tabs/location';
import {Information} from './components/tabs/information';
import {UploadPhotoAlert} from './components/upload-photo-alert';
import {DeletePhotoAlert} from './components/delete-photo-alert';
import {DrawerBody} from './index.styled';
import {SaveAnywayAlert} from './components/save-anyway-alert';
import {SaveWithoutClassesAlert} from './components/save-without-classes-alert';
import {getUser} from '@esgi/core/authentication';
import {LimitAlert} from './components/limit-alert';
import {Drawer} from '@esgi/main/kits/common';

interface Props {
	modalsOwner: RefObject<HTMLDivElement>;
	onDrawerClose: VoidFunction;
	formTouchedRef: MutableRefObject<{
		updateTouchedStatus: () => boolean;
		isInitialized: boolean;
	}>;
}

export function AddStudentProfile({modalsOwner, formTouchedRef, onDrawerClose}: Props) {
	const currentUser = getUser();
	const service = useService(StudentProfileService);

	const canViewExportID = useBehaviorSubject(service.canExportID);
	const initData = useBehaviorSubject(service.initData);
	const initDictionaryData = useBehaviorSubject(service.initDictionaryData);
	const photo = useBehaviorSubject(service.newPhoto);
	const classes = useBehaviorSubject(service.classes);
	const groups = useBehaviorSubject(service.groups);
	const duplicateError = useBehaviorSubject(service.duplicateError);
	const profileMode = useBehaviorSubject(service.mode);
	const usernamePlaceholder = useBehaviorSubject(service.usernamePlaceholder);
	const students = useBehaviorSubject(service.students);
	const limitStudents = useBehaviorSubject(service.limitStudents);
	const [isInitialized, setInitialized] = useState(false);
	const [selectedTab, setSelectedTab] = useState<StudentProfileTab>(
		StudentProfileTab.Profile
	);
	const [isDeletePhotoAlertOpen, setDeletePhotoAlertOpen] = useState(false);
	const [isUploadPhotoAlertOpen, setUploadPhotoAlertOpen] = useState(false);
	const [invalidTabIDs, setInvalidTabIDs] = useState<StudentProfileTab[]>([]);
	const [studentID, setStudentID] = useState('');
	const [isLimitFinished, setLimitFinished] = useState(false);
	const [showEmptyClassError, setShowEmptyClassError] = useState(false);

	const isFormPristine = useCallback(() => service.compareStatesForEquality(), []);

	useEffect(() => {
		service.init(StudentProfileMode.add).subscribe(() => {
			setInitialized(true);
		});
	}, []);
	useEffect(()=>{
		if(students && limitStudents <= students.length){
			setInitialized(false);
			setLimitFinished(true);
		}
	}, [students, limitStudents]);
	useImperativeHandle(
		formTouchedRef,
		() => {
			return {
				updateTouchedStatus() {
					const isEqual = isFormPristine();

					return !isEqual;
				},
				isInitialized,
			};
		},
		[isInitialized, selectedTab, service]
	);

	const userNameTouched = useRef(false);

	const handleNameChange = (firstName: string, lastName: string) => {
		service.usernamePlaceholder.next(((firstName || lastName) && !service.profile.controls.username.value) ? `e.g. ${firstName}.${lastName}` : 'Usеrnamе');
	};

	useStreamEffect(service.profile.controls.firstName.onChanged, (v) => {
		if (profileMode === StudentProfileMode.add && !userNameTouched.current) {
			handleNameChange(v.currState.value, service.profile.value.lastName);
		}
	});
	useStreamEffect(service.profile.controls.lastName.onChanged, (v) => {
		if (profileMode === StudentProfileMode.add && !userNameTouched.current) {
			handleNameChange(service.profile.value.firstName, v.currState.value);
		}
	});
	useStreamEffect(service.profile.controls.username.onChanged, (v) => {
		if (service.profile.controls.username.value) {
			handleNameChange('', '');
		}else{
			handleNameChange(service.profile.value.firstName, service.profile.value.lastName);
		}
	});

	useStreamEffect(service.profile.controls.studentIDN.onChanged, () => {
		setStudentID(service.profile.value.studentIDN);
	});

	const renderTabs = useMemo(() => {
		switch (selectedTab) {
			case StudentProfileTab.Profile:
				return (
					<Profile
						initDictionaryData={initDictionaryData}
						form={service.profile}
						canViewExportID={canViewExportID}
						isInitialized={isInitialized}
						onUserNameTouched={() => (userNameTouched.current = true)}
						isDisabledEyeButton={false}
						usernamePlaceholder={usernamePlaceholder}
					/>
				);
			case StudentProfileTab.Location:
				return (
					<Location form={service.location} classes={classes} groups={groups} />
				);
			case StudentProfileTab.Information:
				return (
					<Information
						initDictionaryData={initDictionaryData}
						form={service.information}
					/>
				);
		}
	}, [selectedTab, profileMode, initData, initDictionaryData, groups, classes, usernamePlaceholder]);

	const saveStudentData = (saveAnyway: boolean) => {
		service.save(saveAnyway).subscribe((res) => {
			if (saveAnyway || (res.isSuccess && !res.errors?.length)) {
				setInitialized(false);
				onDrawerClose();
			}
		});
	};

	const handleSaveStudent = () => {
		service[selectedTab as string].validate().subscribe((value: any) => {
			if (selectedTab === StudentProfileTab.Profile && !value.valid) {
				setInvalidTabIDs((prev) => [...prev, selectedTab]);
				return;
			}
			setInvalidTabIDs([]);

			if (value.valid && !service.location.controls.classIDs.value.length) {
				setShowEmptyClassError(true);
				return;
			}

			saveStudentData(false);
		});
	};

	const handleSaveStudentImage = (data: ImageData) => {
		service.saveImage(data);
		setUploadPhotoAlertOpen(false);
	};

	const handleDeleteStudentImage = () => {
		service.deletePhoto();
		setUploadPhotoAlertOpen(false);
	};

	const handelChangeCurrentTab = (tab: StudentProfileTab) => {
		if (tab !== selectedTab) {
			if (profileMode !== StudentProfileMode.view && ((currentUser?.canEditStudents)
				|| (currentUser?.canEditStudentMetaFields && tab === StudentProfileTab.Information))) {
				service[selectedTab as string].validate().subscribe((value) => {
					if (value.valid) {
						setSelectedTab(tab);
					}
				});
			} else {
				setSelectedTab(tab);
			}
		}
	};
	const cancelWithoutClassesModal = () => {
		setShowEmptyClassError(false);
		setSelectedTab(StudentProfileTab.Location);
	};
	const saveWithoutClasses = () => {
		setShowEmptyClassError(false);
		saveStudentData(false);
	};
	const closeLimitAlert = () =>{
		onDrawerClose();
		setLimitFinished(false);
	};

	return(<>
		{isLimitFinished && <LimitAlert limitStudents={limitStudents} onClose={closeLimitAlert}/>}

			<Drawer.Header
				Icon={UserCard}
				sectionName='Student Profile'
				withActionButton
				actionButtonDisabled={isFormPristine()}
				actionButtonText='Save'
				onActionButtonClick={handleSaveStudent}
				closeDrawer={onDrawerClose}
			/>
			<DrawerBody>
				<FlexBox direction='column'>
					<StudentDetails
						studentId={studentID}
						imageURL={photo?.imageCropUrl}
						fullName={`${initData?.profile?.firstName} ${initData?.profile?.lastName}`}
						isAddMode={profileMode === StudentProfileMode.add}
						onDeleteImage={() => setDeletePhotoAlertOpen(true)}
						onUploadPhotoOpen={() => setUploadPhotoAlertOpen(true)}
						isInitialized={isInitialized}
					/>

					<TabsController
						selectedTab={selectedTab}
						isDisabled={!isInitialized}
						onChange={handelChangeCurrentTab}
						isInitialized={isInitialized}
						invalidTabIDs={invalidTabIDs}
					/>
				</FlexBox>

				{renderTabs}

				{isUploadPhotoAlertOpen && (
					<UploadPhotoAlert
						portalProps={{container: modalsOwner.current}}
						onClose={() => setUploadPhotoAlertOpen(false)}
						imageData={photo}
						onSave={handleSaveStudentImage}
					/>
				)}

				{isDeletePhotoAlertOpen && (
					<DeletePhotoAlert
						portalProps={{container: modalsOwner.current}}
						onDelete={handleDeleteStudentImage}
						onCancel={() => setDeletePhotoAlertOpen(false)}
					/>
				)}

				{duplicateError?.length && (
					<SaveAnywayAlert
						portalProps={{container: modalsOwner.current}}
						onSaveAnyway={() => saveStudentData(true)}
						onCancel={() => service.duplicateError.next(null)}
						name={`${service.profile.value.firstName} ${service.profile.value.lastName}`}
					/>
				)}
				{showEmptyClassError && (
					<SaveWithoutClassesAlert
						onCancel={cancelWithoutClassesModal}
						onSave={saveWithoutClasses}
					/>
				)}
			</DrawerBody>
	</>);
}
