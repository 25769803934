import {styled} from '@esgi/ui/theme';
import {Text} from '@esgi/ui/typography';
import {Select} from '@esgi/ui/controls';

export const SelectRoot = Select.Root;

export const SelectContent = styled(Select.Content, {
	minWidth: '232px',
	position: 'relative',
});

export const SelectField = styled(Select.Field, {
	minWidth: 100,
	maxHeight: 40,

	'& [data-placeholder-content]': {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},

	'& [data-value-content]': {
		display: 'grid',
		minHeight: 20,
		alignItems: 'center',
		justifyContent: 'start',

		[`& > ${Text}`]: {
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
	},
});

export const SelectOption = styled(Select.Option, {
	'&:hover': {
		background: '$primaryBackground',

		'& span': {
			[`& > ${Text}`]: {
				color: '$blue40',
			},
		},
	},
	variants: {
		displayDefaultLabel: {
			true: {
				display: 'flex',
				justifyContent: 'space-between',

				'&:after': {
					content: 'Default',
					color: '$primary80',
				},
			},
		},
	},
});


export const Label = styled(Text, {
	textAlign: 'center',
});
