import {
	BehaviorSubject,
	combineLatest,
	debounceTime,
	map,
	of,
	switchMap,
} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {StateStandardModel} from '@esgi/contracts/esgi/types/esgi.assets/state-standard-domains/standards/search/state-standard-model';
import {DomainModel} from '@esgi/contracts/esgi/types/esgi.assets/state-standard-domains/get/by-id/domain-model';
import {
	DictionariesLocationController,
	V2PagesTestExplorerStateStandardsController,
} from '@esgi/contracts/esgi';
import {OptionItem, StandardListItem} from '../types';
import {ClusterModel} from '@esgi/contracts/esgi/types/esgi.assets/state-standard-domains/get/by-id/cluster-model';
import {getUser} from '@esgi/core/authentication';
import {StateModel} from '@esgi/contracts/esgi/types/esgi.assets/features/common/state-standards/get/states/state-model';

const itemsPerPage = 30;
const USACountryID = 234;

// TODO: replace with generadted type
interface CountryStateModel {
	id: number;
	name: string;
}

export class StandardsService extends BaseService {
	public standards$ = new BehaviorSubject<StateStandardModel[]>([]);

	// Selected filters chips
	public selectedStandards$ = new BehaviorSubject<OptionItem[]>([]);
	public selectedStandardsIDs$ = new BehaviorSubject<number[]>([]);
	public selectedGradeLevelIDs$ = new BehaviorSubject<number[]>([]);
	public selectedContentAreaIDs$ = new BehaviorSubject<number[]>([]);

	// Standards Drawer State
	public isStandardsDrawerInitialised$ = new BehaviorSubject(false);
	public isContentAreasLoading$ = new BehaviorSubject(true);
	public isDomainsLoading$ = new BehaviorSubject(true);
	public isStandardsLoading$ = new BehaviorSubject(true);
	public hasMoreStandards$ = new BehaviorSubject(true);
	public currentStandardsPageIndex$ = new BehaviorSubject(1);
	public domainModels$ = new BehaviorSubject<DomainModel[]>([]);
	public standardsSearchKeyword$ = new BehaviorSubject('');
	public selectedDomain$ = new BehaviorSubject<OptionItem['value']>(null);
	public selectedContentArea$ = new BehaviorSubject<OptionItem['value']>(null);
	public selectedGradeLevel$ = new BehaviorSubject<OptionItem['value']>(null);
	public selectedStandardType$ = new BehaviorSubject<OptionItem['value']>(0);
	public standardTypeOptions$ = new BehaviorSubject<OptionItem[]>([
		{label: 'Common Core', value: 0},
	]);

	public standardsList$ = new BehaviorSubject<StandardListItem[]>([]);
	public standardsContentAreas$ = new BehaviorSubject<OptionItem[]>([]);
	public domainsList$ = new BehaviorSubject<Array<OptionItem & ClusterModel>>(
		[]
	);

	private readonly currentUser = getUser();
	private standardsController =
		new V2PagesTestExplorerStateStandardsController();
	private locationController = new DictionariesLocationController();

	constructor() {
		super();

		this.completeOnDestroy(combineLatest([this.currentStandardsPageIndex$]))
			.pipe(
				switchMap(([currentPageIndex]) => {
					if (currentPageIndex > 1) {
						return this.getStandards(
							this.selectedDomain$.value,
							currentPageIndex,
							itemsPerPage
						);
					}
					return of(null);
				})
			)
			.subscribe((result) => {
				if (result?.value) {
					const {stateStandardModels, searchCount} = result.value;
					this.onStandardsLoaded(stateStandardModels, searchCount);
				}
			});

		this.completeOnDestroy(combineLatest([this.standardsSearchKeyword$]))
			.pipe(
				debounceTime(500),
				switchMap(() => {
					if (this.selectedDomain$.value) {
						this.standards$.next([]);
						this.standardsList$.next([]);

						return this.getStandards(
							this.selectedDomain$.value,
							1,
							itemsPerPage
						);
					}

					return of(null);
				})
			)
			.subscribe((result) => {
				if (result?.value) {
					const {stateStandardModels, searchCount} = result.value;
					this.onStandardsLoaded(stateStandardModels, searchCount);
				}
			});
	}

	public onStandardsLoaded(
		stateStandardModels: StateStandardModel[],
		searchCount: number
	) {
		this.standards$.next([...this.standards$.value, ...stateStandardModels]);
		this.hasMoreStandards$.next(searchCount === itemsPerPage);

		const selectedDomainModel = this.domainModels$.value.find(
			({id}) => id === this.selectedDomain$.value
		);

		let clusterModels;

		if (selectedDomainModel?.clusterModels.length) {
			clusterModels = selectedDomainModel?.clusterModels
				?.map((clusterModel) => ({
					...clusterModel,
					standards: stateStandardModels
						.filter(({clusterID}) => clusterModel.id === clusterID)
						.map((item) => ({
							...item,
							value: item.id,
							label: item.name,
							description: item.fullDescription,
						}))
						.reduce(
							(acc, curr, idx, arr) => {
								const children = arr.filter((item) => item.parentStandardID === curr.id);
								if (children.length) {
									children.forEach(child => {
										const idx = arr.findIndex(item => item.id === child.id);
										arr.splice(idx, 1);
									});
								}

								return [
									...acc,
									{
										...curr,
										children,
									},
								];
							}, []
						),
				}))
				.filter(({standards}) => standards.length);
		} else {
			clusterModels = [
				{
					id: null,
					name: null,
					standards: selectedDomainModel.standardModels
						.map((item) => ({
							...item,
							value: item.id,
							label: item.name,
							description: item.fullDescription,
						}))
						.reduce((acc, curr, idx, arr) => {
							if (!curr.parentStandardID) {
								if (stateStandardModels.length > 1) {
									acc.push({
										...curr,
										children: stateStandardModels
											.filter(
												({parentStandardID}) => parentStandardID === curr.id
											)
											.map((item) => ({
												...item,
												value: item.id,
												label: item.name,
												description: item.fullDescription,
											})),
									});
								} else {
									acc.push({
										...curr,
										children: arr.filter(
											({parentStandardID}) => parentStandardID === curr.id
										),
									});
								}
							}

							return acc;
						}, []),
				},
			];
		}

		this.isStandardsLoading$.next(false);
		this.standardsList$.next(clusterModels);
	}

	public getStandards(
		domainID?: number,
		pageIndex?: number,
		itemsPerPage?: number
	) {
		this.isStandardsLoading$.next(true);

		return this.standardsController.search({
			domainID,
			name: this.standardsSearchKeyword$.value,
			pageIndex,
			clusterIDs: [],
			itemsPerPage: itemsPerPage || 300,
		});
	}

	public getStandardsContentAreas() {
		return this.standardsController.contentAreas({
			stateID: this.selectedStandardType$.value,
		});
	}

	public getStandardsDomains(contentAreaID: number, gradeLevelID: number) {
		return this.standardsController.domains({
			contentAreaID,
			gradeLevelID,
			stateID: this.selectedStandardType$.value,
		});
	}

	public getGradeLevels(contentAreaID: number) {
		return this.standardsController.gradeLevels({
			contentAreaID,
			stateID: this.selectedStandardType$.value,
		});
	}

	public getStates() {
		return this.standardsController
			.states()
			.pipe(
				switchMap(({value: {states: standardsStates}}) =>
					this.locationController.states({countryID: USACountryID}).pipe(
						// TODO: fix generated response model
						map((response: unknown) => {
							if (!response) {
								return;
							}

							const countryStates = response as CountryStateModel[];
							const standardTypeOptions = this.buildStandardTypeOptions(
								standardsStates,
								countryStates
							);
							this.updateSelectedStandardType(standardTypeOptions);
							this.standardTypeOptions$.next(standardTypeOptions);
						})
					)
				)
			)
			.subscribe();
	}

	public onGradeLevelChange = (gradeLevelID: number) => {
		if (this.selectedGradeLevelIDs$.value.includes(gradeLevelID)) {
			this.selectedGradeLevelIDs$.next(
				this.selectedGradeLevelIDs$.value.filter((id) => id !== gradeLevelID)
			);
			return;
		}

		this.selectedGradeLevelIDs$.next([
			...this.selectedGradeLevelIDs$.value,
			gradeLevelID,
		]);
	};

	public onContentAreaChange = (contentAreaID: number) => {
		if (this.selectedContentAreaIDs$.value.includes(contentAreaID)) {
			this.selectedContentAreaIDs$.next(
				this.selectedContentAreaIDs$.value.filter((id) => id !== contentAreaID)
			);
			return;
		}

		this.selectedContentAreaIDs$.next([
			...this.selectedContentAreaIDs$.value,
			contentAreaID,
		]);
	};

	public onSelectedStandardRemove = (selectedStandardID: number): void => {
		const currentValues = this.selectedStandards$.value;

		const updatedValues = currentValues.reduce<{
			selectedStandards: OptionItem[];
			selectedStandardIDs: Set<number>;
			selectedGradeLevelIDs: Set<number>;
			selectedContentAreaIDs: Set<number>;
		}>(
			(acc, standart) => {
				const {value, gradeLevel, contentArea} = standart;

				if (value !== selectedStandardID) {
					acc.selectedStandards.push(standart);
					acc.selectedStandardIDs.add(value);
					acc.selectedGradeLevelIDs.add(gradeLevel);
					acc.selectedContentAreaIDs.add(contentArea);
				}

				return acc;
			},
			{
				selectedStandards: [],
				selectedStandardIDs: new Set<number>(),
				selectedGradeLevelIDs: new Set<number>(),
				selectedContentAreaIDs: new Set<number>(),
			}
		);

		this.selectedStandards$.next(updatedValues.selectedStandards);
		this.selectedStandardsIDs$.next(
			Array.from(updatedValues.selectedStandardIDs)
		);
		this.selectedGradeLevelIDs$.next(
			Array.from(updatedValues.selectedGradeLevelIDs)
		);
		this.selectedContentAreaIDs$.next(
			Array.from(updatedValues.selectedContentAreaIDs)
		);
	};

	public onStandardsClear = () => {
		this.selectedStandardsIDs$.next([]);
		this.selectedStandards$.next([]);
		this.selectedGradeLevelIDs$.next([]);
		this.selectedContentAreaIDs$.next([]);
	};

	public override dispose() {
		this.standardsController.dispose();
	}

	private buildStandardTypeOptions(
		standardsStates: StateModel[],
		countryStates: CountryStateModel[]
	): OptionItem[] {
		const countryStatesMap = new Map(
			countryStates.map((state) => [state.id, state.name])
		);

		const {commonCoreOption, otherOptions} = standardsStates.reduce<{
			commonCoreOption: OptionItem | null;
			otherOptions: OptionItem[];
		}>(
			(optionsResult, {id}) => {
				if (id === 0) {
					optionsResult.commonCoreOption = {value: 0, label: 'Common Core'};
				} else {
					const name = countryStatesMap.get(id);
					if (name) {
						optionsResult.otherOptions.push({value: id, label: name});
					}
				}
				return optionsResult;
			},
			{commonCoreOption: null, otherOptions: []}
		);

		otherOptions.sort((a, b) => a.label.localeCompare(b.label));

		return commonCoreOption
			? [commonCoreOption, ...otherOptions]
			: otherOptions;
	}

	private updateSelectedStandardType(standardTypeOptions: OptionItem[]): void {
		const currentUserStateID = this.currentUser.stateID;
		const isCurrentUserStateExists = standardTypeOptions.find(
			({value}) => value === currentUserStateID
		);
		if (isCurrentUserStateExists) {
			this.selectedStandardType$.next(currentUserStateID);
		}
	}
}
