import {Fragment, useEffect, useMemo, useState} from 'react';
import {GridBox} from '@esgi/ui/layout';
import {useEventEffect} from '@esgillc/events';
import {useBehaviorSubject} from '@esgillc/ui-kit/utils';
import {BingoUI, useBingo, BingoContext} from '@esgi/main/features/teacher/bingo';
import {Activities} from '@esgi/main/kits/activities';
import {TestsPanel, useTestsSelection, SelectAllTestsEvent} from '@esgi/main/kits/common';
import {LeftSidebar, Page, Header, Divider} from '../layout/index.styled';
import {AddTestDrawer} from '@esgi/main/features/teacher/drawers';
import {useActivitiesContext} from '../layout/outlet-context';
import {SubjectTab, useSubjects} from '@esgi/main/libs/store';

export default function Bingo() {
	const {service} = useBingo();
	const {snackbarRef} = useActivitiesContext();
	const {
		entitySet,
		entitySetAll,
		entityUnsetAll,
		selectedEntities,
	} = useTestsSelection();

	const [{data: subjects}] = useSubjects();
	const tests = useBehaviorSubject(service.tests$);
	const classes = useBehaviorSubject(service.studentServiceData.classes$);
	const groups = useBehaviorSubject(service.studentServiceData.groups$);
	const students = useBehaviorSubject(service.studentServiceData.students$);
	const selectedStudents = useBehaviorSubject(service.selectedStudents$);
	const previewURL = useBehaviorSubject(service.previewURL$);
	const selectedSubject = useBehaviorSubject(service.selectedSubject$);

	const isTestsLoaded = useBehaviorSubject(service.isTestsLoaded$);
	const isTestsLoading = useBehaviorSubject(service.isTestsLoading$);
	const [showAddTestsDrawer, setShowAddTestsDrawer] = useState(false);
	const studentsState = useMemo(() => ({
		classIDs: [],
		groupIDs: [],
		studentIDs: selectedStudents.map((item) => item.studentId),
	}), [selectedStudents]);

	useEventEffect(SelectAllTestsEvent, () => {
		if (selectedEntities.length === tests.length) {
			entityUnsetAll();
			return;
		}

		entitySetAll(tests.map((item) => String(item.id)), []);
	}, []);

	useEffect(() => {
		if (!isTestsLoaded) {
			return;
		}

		entityUnsetAll();
	}, [isTestsLoaded, entityUnsetAll]);

	useEffect(() => {
		service.setSelectedTests(selectedEntities);
	}, [service, selectedEntities]);

	return (
		<BingoContext.Provider value={service}>
			<Page>
				<LeftSidebar>
					<Activities.StudentsControl
						classes={classes}
						groups={groups}
						students={students}
						onChange={(students) => service.setSelectedStudentList(students)}
					/>
				</LeftSidebar>
				<TestsPanel.Layout.Content>
					<TestsPanel.Root studentsState={studentsState}>
						<TestsPanel.Students.Root>
							<TestsPanel.Subject.Root onSubjectChange={(subject) => service.setSelectedSubject(subject)}>
								<Header>
									<TestsPanel.Students.Info counterTitle='bingo' />
									<Divider />
									<TestsPanel.Subject.Control />
								</Header>
								<TestsPanel.Tests.Root>
									{isTestsLoading ? (
										<TestsPanel.Tests.ListLoading />
									) : !tests.length ? (
										<BingoUI.EmptyList onAllTestClick={() => setShowAddTestsDrawer(true)} />
									) : (
										<Fragment>
											<GridBox gap={4} flow='column' align='center' justify='start'>
												<TestsPanel.Tests.Controls.SelectAll />
												<TestsPanel.Tests.Controls.Sort />
											</GridBox>
											<TestsPanel.Tests.List
												list={tests}
												listVocabulary={selectedEntities}
												onSetSelectedEntities={entitySet}
											>
												{(listItem) => (
													<TestsPanel.Tests.Item
														key={listItem.id}
														item={listItem}
														widgetRight={(
															<BingoUI.TestWidget testResults={listItem.testResults} />
														)}
														isSelectable
													/>
												)}
											</TestsPanel.Tests.List>
										</Fragment>
									)}
								</TestsPanel.Tests.Root>
							</TestsPanel.Subject.Root>
						</TestsPanel.Students.Root>
					</TestsPanel.Root>
				</TestsPanel.Layout.Content>
				<BingoUI.BingoPanel />
				{previewURL &&
					<BingoUI.PreviewDialog
						onClose={() => service.clearPreview()}
						onDownload={() => service.createGame()}
						previewURL={previewURL}
					/>
				}
			</Page>
			{showAddTestsDrawer &&
				<AddTestDrawer<SubjectTab>
					onClose={() => setShowAddTestsDrawer(false)}
					subject={subjects.find(({id}) => id === selectedSubject.id)}
					snackbarRef={snackbarRef}
				/>
			}
		</BingoContext.Provider>
	);
}