import {styled} from '@esgi/ui/theme';
import {Box, FlexBox, GridBox} from '@esgi/ui/layout';
import {Alert} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {DroppableTextEditor} from '@esgi/ui/form-controls';
import {Button} from '@esgi/ui';
import {SkeletonStyles} from '@esgi/ui/skeleton';

export const BodyContainer = styled(Box, {
	width: '100%',
	maxWidth: '508px',
});

export const AlertHeader = styled(Alert.Header, {
	'& > div': {
		gridTemplateColumns: '1fr auto',
	},
});
export const ButtonsContainer = styled(FlexBox, {
	borderRight: '1px solid $neutral92',
	'& button': {
		marginRight: '16px',
	},
});

export const HeaderContent = styled(GridBox, {
	gridTemplateColumns: '1fr auto',
});

export const ModalTitle = styled(Text, {
	marginRight: '6px',
});

export const DescriptionLink = styled(Text, {
	cursor: 'pointer',
	transition: 'color .2s ease',
	'&:hover': {
		color: '$secondary56',
	},
});

export const DraggableTag = styled(DroppableTextEditor.DraggableTag, {
	marginRight: 8,
	marginBottom: 8,
	'&:last-child': {
		marginRight: 0,
	},
});

export const Description = styled(Text, {
	paddingBottom: 8,
	marginBottom: 16,
	display: 'block',

	variants: {
		skeleton: {
			true: SkeletonStyles,
		},
	},
});

export const StudentLanguage = styled(FlexBox, {
	justifyContent: 'space-between',
	padding: '14px 12px',
	backgroundColor: '$neutral96',
	borderRadius: 6,
	marginBottom: 12,

	variants: {
		skeleton: {
			true: SkeletonStyles,
		},
	},
});

export const FormElementWrapper = styled(Box, {
	'&>div': {
		marginBottom: 0,
	},
});

export const AlertFooter = styled(Alert.Footer, {
	paddingTop: 0,
	'&&': {
		justifyContent: 'space-between',
	},
});

export const AlertRoot = styled(Alert, {
	maxWidth: 508,
});

export const TextEditorBox = styled(DroppableTextEditor.TextBox, {
	'&>div': {
		height: 240,
		overflowY: 'auto',
		minHeight: 'unset',
	},

	variants: {
		skeleton: {
			true: SkeletonStyles,
		},
	},
});

export const IconButton = styled(Button.Icon, {
	'& svg': {
		fill: '$base',
		'& path': {
			fill: '$base',
		},
	},

	variants: {
		editMode: {
			true: {
				'& svg': {
					fill: '$primary',
					'& path': {
						fill: '$primary',
					},
				},
			},
		},
	},
});
