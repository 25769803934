import {Alert, alertColorsConfig} from '@esgi/ui/alert';
import {Text} from '@esgi/ui/typography';
import {Button} from '@esgi/ui';
import {GridBox} from '@esgi/ui/layout';
import {useMemo} from 'react';
import {SubjectTab, useSubjects} from '@esgi/main/libs/store';
import {isNull} from 'underscore';

type Props = {
	onClose: VoidFunction;
	testID: number;
	subjectID: SubjectTab['id'];
	onConfirm: (args: {subjectID: SubjectTab['id']; testID: number}) => void;
};

export function RemoveAlert({onClose, subjectID, testID, onConfirm}: Props) {
	const alertRef = Alert.useRef();
	const closeAlert = Alert.useClose(alertRef, onClose);

	const [{data: subjects, loaded: isSubjectsLoaded}] = useSubjects();

	const {currentSubject, currentTest} = useMemo(() => {
		const subject = subjects.find(({id}) => id === subjectID);
		const test = subject?.tests.find(({id}) => id === testID);

		return {
			currentSubject: subject ?? null,
			currentTest: test ?? null,
		};
	}, [subjectID, subjects, testID]);

	const confirmAndClose = () => {
		if (isNull(currentSubject)) {
			return;
		}

		onConfirm({subjectID: currentSubject.id, testID});
		closeAlert();
	};

	return (
		<Alert
			colorConfig={alertColorsConfig.negative}
			modalManagerRef={alertRef}
			css={{
				'& [data-alert-content]': {
					width: 510,
				},
			}}
		>
			{isSubjectsLoaded && !isNull(currentTest) && !isNull(currentSubject) && (
				<>
					<Alert.Header onCloseIconClick={closeAlert}>
						<Text size='large' color='negative'>
							Remove Test from Subject
						</Text>
					</Alert.Header>
					<Alert.Body>
						<GridBox>
							<Text size='medium' color='neutral40'>
								Are you sure you want to delete the test "{currentTest.name}" from the subject {currentSubject.name}?
							</Text>
						</GridBox>
					</Alert.Body>
					<Alert.Footer>
						<GridBox gap='3' flow='column'>
							<Button color='tertiary' onClick={closeAlert}>
								<Text size='medium' bold color='base'>
									Cancel
								</Text>
							</Button>
							<Button color='secondary' onClick={confirmAndClose}>
								<Text size='medium' bold color='base'>
									Confirm
								</Text>
							</Button>
						</GridBox>
					</Alert.Footer>
				</>
			)}
		</Alert>
	);
}
