import {BehaviorSubject} from 'rxjs';
import {BaseService} from '@esgi/core/service';
import {subjectsStore, SubjectTab} from '@esgi/main/libs/store';

export class SubjectRootService extends BaseService {
	public loaded$ = new BehaviorSubject(false);
	public subjects$ = new BehaviorSubject<SubjectTab[]>([]);
	public selectedSubject$ = new BehaviorSubject<SubjectTab | null>(null);

	private subjectStorage = subjectsStore();

	constructor() {
		super();

		this.subjectStorage.get().subscribe({
			next: ({data: subjects, loaded}) => {
				const filtered = subjects.filter(({hidden}) => !hidden);
				this.subjects$.next(filtered);
				this.selectedSubject$.next(filtered[0]);
				this.loaded$.next(loaded);
			},
		});
	}

	public subjectSelect(subjectId: number) {
		const selectedSubject = this.get(subjectId);
		if (!selectedSubject) {
			return;
		}

		this.selectedSubject$.next(selectedSubject);
	}

	public override dispose() {
		this.subjectStorage.dispose();
	}

	private get(subjectId: number) {
		const subject = this.subjects$.value.find((item) => item.id === subjectId);
		return subject ?? null;
	}
}