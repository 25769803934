import React, {Dispatch} from 'react';
import {useSpring, config, animated} from 'react-spring';
import {CardHeaderBox, TestLabelsBox} from './index.styled';
import {CardTitle} from '../card-title';
import {TestType} from '@esgi/main/libs/core';
import {TestColorIndicator, TestContentArea} from '@esgi/main/kits/common';
import {TestTypeLabel} from '../../../../../../../../kit';
import {TestSession} from '@esgi/main/features/teacher/home';
import {Class, SubjectTab} from '@esgi/main/libs/store';
import {CardActionButtons} from '../card-action-buttons';
import {ClassGroupActionButtonsProps, StudentActionButtonsProps} from '../card-action-buttons/types';
import {StateStandard} from '../../../../../../../../types';
import {useUser} from '@esgi/core/authentication';
import {MultipleLabels} from '../../../../components/multiple-labels';

type Props = {
	name: string;
	type: TestType;
	contentArea: TestContentArea;
	standards: StateStandard[];
	lastTestSessions: TestSession[];
	sessionsCount: number;
	maxScore: number;
	userCanEditTest: boolean;
	userCanRemoveTest: boolean;
	openTestDetails: VoidFunction;
	isSmallScreen: boolean;
	testID: number;
	onTestMoveTo: Dispatch<{previousSubject: SubjectTab; newSubject: SubjectTab; testID: number}>;
	onRemoveTest: Dispatch<{subjectID: SubjectTab['id']; testID: number}>;
	classID: Class['id'];
	subjectID: SubjectTab['id'];
} & (StudentActionButtonsProps | ClassGroupActionButtonsProps);

export function CardHeader({
	contentArea,
	name,
	type,
	lastTestSessions,
	sessionsCount,
	maxScore,
	userCanEditTest,
	userCanRemoveTest,
	openTestDetails,
	isSmallScreen,
	testID,
	onTestMoveTo,
	onRemoveTest,
	classID,
	subjectID,
	...props
}: Props) {
	const animatedIndicatorStyles = useSpring({
		height: `calc(100% + ${isSmallScreen ? 0 : 96}px)`,
		marginBottom: isSmallScreen ? 0 : -96,
		config: {
			...config.gentle,
			clamp: true,
		},
	});

	const user = useUser();

	const userStateStandard = props.standards.filter(({stateID}) => stateID === user.stateID);
	const commonStandards = props.standards.filter(({stateID}) => stateID === 0);

	const standards = userStateStandard.length ? userStateStandard : commonStandards;

	return (
		<CardHeaderBox>
			<animated.div style={animatedIndicatorStyles}>
				<TestColorIndicator testID={testID} />
			</animated.div>

			<CardTitle titleText={name} />
			<TestLabelsBox>
				<TestTypeLabel testType={type} />
				{standards.length > 0 && <MultipleLabels items={standards.map(s => s.name)}/>}
			</TestLabelsBox>
			{!isSmallScreen && (
				<CardActionButtons
					lastTestSessions={lastTestSessions}
					sessionsCount={sessionsCount}
					maxScore={maxScore}
					openTestDetails={openTestDetails}
					userCanEditTest={userCanEditTest}
					userCanRemoveTest={userCanRemoveTest}
					testID={testID}
					onTestMoveTo={onTestMoveTo}
					onRemoveTest={onRemoveTest}
					classID={classID}
					subjectID={subjectID}
					{...props}
				/>
			)}
		</CardHeaderBox>
	);
}
