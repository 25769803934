import {useEffect, useMemo} from 'react';
import {useEventEffect} from '@esgillc/events';
import {
	TestsPanel,
	useTestsSelection,
	SelectAllTestsEvent,
} from '@esgi/main/kits/common';
import {GridBox} from '@esgi/ui/layout';

export function TestsSelectionPanel({
	selectedStudent,
	onSubjectChange,
	isTestsLoading,
	isTestsLoaded,
	tests,
	setSelectedTests,
}) {
	const {entitySet, entitySetAll, entityUnsetAll, selectedEntities} =
		useTestsSelection();

	useEventEffect(
		SelectAllTestsEvent,
		() => {
			if (selectedEntities.length === tests.length) {
				entityUnsetAll();
				return;
			}

			entitySetAll(
				tests.map((item) => String(item.id)),
				[]
			);
		},
		[]
	);

	useEffect(() => {
		if (!isTestsLoaded) {
			return;
		}

		entityUnsetAll();
	}, [isTestsLoaded, entityUnsetAll]);

	useEffect(() => {
		setSelectedTests(selectedEntities);
	}, [selectedEntities]);

	const studentsState = useMemo(
		() => ({
			classIDs: [],
			groupIDs: [],
			studentIDs: selectedStudent?.id ? [selectedStudent.id] : [],
		}),
		[selectedStudent]
	);

	return (
		<TestsPanel.Layout.Content>
			<TestsPanel.Root studentsState={studentsState}>
				<TestsPanel.Students.Root>
					<TestsPanel.Subject.Root onSubjectChange={onSubjectChange}>
						<TestsPanel.Layout.Header>
							<TestsPanel.Students.Info counterTitle='ai-analysis' />
							<TestsPanel.Layout.Divider />
							<TestsPanel.Subject.Control />
						</TestsPanel.Layout.Header>
						<TestsPanel.Tests.Root>
							{isTestsLoading ? (
								<TestsPanel.Tests.ListLoading />
							) : !tests?.length ? (
								<TestsPanel.Tests.ListEmpty />
							) : (
								<>
									<GridBox gap={4} flow='column' align='center' justify='start'>
										<TestsPanel.Tests.Controls.SelectAll />
										<TestsPanel.Tests.Controls.Sort />
									</GridBox>
									<TestsPanel.Tests.List
										list={tests}
										listVocabulary={selectedEntities}
										onSetSelectedEntities={entitySet}
									>
										{(listItem) => (
											<TestsPanel.Tests.Item
												key={listItem.id}
												item={listItem}
												isSelectable
											/>
										)}
									</TestsPanel.Tests.List>
								</>
							)}
						</TestsPanel.Tests.Root>
					</TestsPanel.Subject.Root>
				</TestsPanel.Students.Root>
			</TestsPanel.Root>
		</TestsPanel.Layout.Content>
	);
}
