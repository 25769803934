import {styled} from '@esgi/ui/theme';
import {ToggleGroup} from '@esgi/ui/controls';

export const ToggleGroupRoot = styled(ToggleGroup.Root, {
	marginBottom: '24px',
	width: '100%',
});

export const ToggleGroupContent = styled(ToggleGroup.Content, {
	width: '100%',
	background: 'none',
});

export const ToggleGroupTitle = styled(ToggleGroup.Title, {
	marginBottom: '12px',
});

export const ToggleGroupItem = styled(ToggleGroup.Item, {
	border: '1px solid $neutral92',
	backgroundColor: '$surface',
	justifyContent: 'flex-start',
	minWidth: '228px',
	padding: '10px 12px',
	marginBottom: '6px',

	'&[data-state=off] + [data-state=off]': {
		'&[data-orientation=vertical]::before': {
			height: 0,
		},
	},
});
