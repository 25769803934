import {ContextMenu, isUndefined} from '@esgi/ui';
import {Plus} from '@esgi/ui/icons';
import {Text} from '@esgi/ui/typography';
import {AddModeMenuListItem} from '../types';
import {ButtonIconable} from './button-iconable.styled';
import {Tooltip} from '@esgi/ui/tooltip';

type Props = {
	addSectionModeItems: AddModeMenuListItem[];
	onOpenChange: (open: boolean) => void;
	isActive: boolean;
	isAddSectionModeDisabled: boolean;
	dataCy?: string;
	tooltipText: string;
};

export function AddSectionMode({addSectionModeItems, onOpenChange, isActive, isAddSectionModeDisabled, dataCy, tooltipText}: Props) {
	return (
		<ContextMenu.Root onOpenChange={onOpenChange} data-cy={dataCy} modal={false}>
			<ContextMenu.Trigger asChild={false} disabled={isAddSectionModeDisabled} dataCy={`${dataCy}-trigger`}>
				<Tooltip>
					<Tooltip.Trigger>
						<ButtonIconable
							withBackgroundHover
							active={isActive}
							dataCy={`${dataCy}-trigger-button`}
						>
							<Plus />
						</ButtonIconable>
					</Tooltip.Trigger>
					{tooltipText &&
						<Tooltip.Content variant='secondary'>
							<Text size='small'>{tooltipText}</Text>
						</Tooltip.Content>
					}
				</Tooltip>
			</ContextMenu.Trigger>
			<ContextMenu.Content dataCy={`${dataCy}-content`}>
				<ContextMenu.Group>
					{addSectionModeItems.map(({itemText, Icon, onClick, permitted, dataCy, notPermittedTooltipText}, index) => (
						<Tooltip open={permitted || isUndefined(notPermittedTooltipText) ? false : undefined} key={index}>
							<Tooltip.Trigger>
								<ContextMenu.Item onClick={onClick} disabled={!permitted} dataCy={dataCy}>
									<Icon />
									<Text size='medium' data-cy={`${dataCy}-label`}>
										{itemText}
									</Text>
								</ContextMenu.Item>
							</Tooltip.Trigger>
							<Tooltip.Content css={{whiteSpace: 'pre-line', textAlign: 'center'}}>
								<Text size='large'>{notPermittedTooltipText}</Text>
							</Tooltip.Content>
						</Tooltip>
					))}
				</ContextMenu.Group>
			</ContextMenu.Content>
		</ContextMenu.Root>
	);
}
