export {SubjectLevel} from '@esgi/contracts/esgi/types/esgi.enums/subject-level';
export {type SubjectTab} from '@esgi/main/libs/store';
export {type Test} from '@esgi/contracts/esgi/types/esgi.assets/features/esgi.aianalysis/test';
export {SubjectType} from '@esgi/contracts/esgi/types/esgi.enums/subject-type';

export type StudentType = {
	id: number;
	firstName?: string;
	lastName?: string;
	gradeLevelID?: number;
	teacherID?: number;
	schoolID?: number;
	avatarURL?: string;
};

export type GradeLevel = {
	id: number;
	shortName: string;
};

export const GradeLevelsMap: GradeLevel[] = [
	{id: 100, shortName: 'Other'},
	{id: 1, shortName: 'PK(3)'},
	{id: 2, shortName: 'PK(4)'},
	{id: 3, shortName: 'PK'},
	{id: 4, shortName: 'K'},
	{id: 5, shortName: '1'},
	{id: 6, shortName: '2'},
	{id: 7, shortName: '3'},
	{id: 8, shortName: '4'},
	{id: 9, shortName: '5'},
	{id: 10, shortName: '6'},
	{id: 11, shortName: '7'},
	{id: 12, shortName: '8'},
	{id: 13, shortName: '9'},
	{id: 14, shortName: '10'},
	{id: 15, shortName: '11'},
	{id: 16, shortName: '12'},
	{id: 17, shortName: 'TK'},
];
