import {IconComponent} from '@esgi/ui';
import {useMemo} from 'react';
import {To} from 'react-router-dom';
import {menuIconByPath, menuLabelByRoute} from '../../constants';
import {routes} from '@esgi/main/libs/core';
import {useUserFeatures} from 'apps/main/pages/teacher/src/user-features-context';

export type NavigateItem = {
	dataCy: string;
	iconDataCy: string;
	to: To;
	label: string;
	permitted?: boolean;
	Icon: IconComponent;
};

export enum AdditionalElementType {
	Divider = 'Divider',
}

export function useNavigateItems({canExploreStudents}: {canExploreStudents: boolean}) {
	const {AiAnalysis} = useUserFeatures();
	return useMemo<(NavigateItem | AdditionalElementType)[]>(
		() => {
			const items = [
				{
					dataCy: 'nav-item-home',
					iconDataCy: 'home-icon',
					to: routes.teacher.home.root,
					label: menuLabelByRoute.home,
					Icon: menuIconByPath.home,
				},
				{
					dataCy: 'nav-item-reports',
					iconDataCy: 'reports-icon',
					to: routes.teacher.reports.root,
					label: menuLabelByRoute.reports,
					Icon: menuIconByPath.reports,
				},
				{
					dataCy: 'nav-item-test-explorer',
					iconDataCy: 'test-explorer-icon',
					to: routes.teacher.testExplorer,
					label: menuLabelByRoute.testExplorer,
					Icon: menuIconByPath.testExplorer,
				},

				AdditionalElementType.Divider,

				{
					dataCy: 'nav-item-activities',
					iconDataCy: 'activities-icon',
					to: routes.teacher.activities.root,
					label: menuLabelByRoute.activities,
					Icon: menuIconByPath.activities,
				},
				{
					dataCy: 'nav-item-assignments',
					iconDataCy: 'assignments-icon',
					to: routes.teacher.assignments.root,
					label: menuLabelByRoute.assignments,
					Icon: menuIconByPath.assignments,
				},

				AdditionalElementType.Divider,

				{
					dataCy: 'nav-item-student-manager',
					iconDataCy: 'student-manager-icon',
					to: routes.teacher.studentManager,
					label: menuLabelByRoute.studentManager,
					permitted: canExploreStudents,
					Icon: menuIconByPath.studentManager,
				},
				{
					dataCy: 'nav-item-parent-conferencer',
					iconDataCy: 'parent-conferencer-icon',
					to: routes.teacher.parentConferencer,
					label: menuLabelByRoute.parentConferencer,
					Icon: menuIconByPath.parentConferencer,
				},
				AdditionalElementType.Divider,
			];
			if (AiAnalysis) {
				return [...items, {
					dataCy: 'nav-item-ai-analysis',
					iconDataCy: 'ai-analysis-icon',
					to: routes.teacher.aiAnalysis.setup,
					label: menuLabelByRoute.aiAnalysis,
					Icon: menuIconByPath.aiAnalysis,
				},];
			}
			return items;
		}
		, [canExploreStudents, AiAnalysis],
	);
}
