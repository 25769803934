import {SelectField, TextStyled} from './index.styled';
import {Text} from '@esgi/ui/typography';
import {Select as SelectUI} from '@esgi/ui/controls';
import {ComponentProps} from 'react';
import {FlexBox} from '@esgi/ui/layout';
import {ReportSelectOption} from '../../../../types/table-level-report-service-types';

type Props = Pick<ComponentProps<typeof SelectUI.Root>, 'value' | 'onValueChange'> & {
	label: string,
	options: ReportSelectOption[],
}

export function ReportSelect(props: Props) {
	const {label, options, value, onValueChange} = props;

	return (
		<FlexBox align='center' dataCy='filter-report'>
			<TextStyled data-cy='label' bold size='medium'>{label}:</TextStyled>
			{options.length > 1 &&
				<SelectUI.Root
					value={value}
					onValueChange={onValueChange}
				>
					<SelectField/>
					<SelectUI.Content>
						{options.map(({id, name}) =>
							<SelectUI.Option
								key={id}
								value={id.toString()}
							>
								<Text size='medium' bold>{name}</Text>
							</SelectUI.Option>
						)}
					</SelectUI.Content>
				</SelectUI.Root>
			}
			{options.length === 1 &&
				<Text data-cy='field-value' size='medium' bold>{options[0].name}</Text>
			}
		</FlexBox>
	);
}
